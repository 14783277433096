:root {
  --white: #ffffff;
  --black: rgba(6,8,4,1);
  --grey: #808080;

  --primary: var(--black);
  --secondary: var(--white);

  --assertive: #ff00ff;
  --focus: #ff00ff;
  --color-error: #ff0000;
  --color-sucess: #4dc247;
}

.palette-primary {
    --primary: var(--black);
    --secondary: var(--grey);
}

.palette-black {
    --primary: var(--white);
    --secondary: var(--black);
}

.palette-red {
    --primary: var(--black);
    --secondary: var(--color-error);
}
