#Footer {
    --width: 100%;
    --height: auto; //var(--header-height);
    --color: var(--black); //var(--secondary);
    --bg: transparent; //var(--primary);
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-xs);
}

#Footer {
    @include font-sans();
    @include z-index($z-index-header);

    position: fixed;
    width: var(--width);
    height: var(--height);
    right: 0;
    bottom: 0;
    padding: var(--padding-v) var(--padding-h);

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--color);
    background-color: var(--bg);

    text-transform: uppercase;
    font-size: var(--font-size-xsmall);
    
    a {
        color: currentColor;
        text-decoration: none;
    }

    span {
        @include font-sans-secondary();
      
        font-size: var(--font-size-small);
        margin-left: rem(25) ;
    }
}
