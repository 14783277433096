#Header {
    --width: 100%;
    --height: auto; //var(--header-height);
    --color: var(--black); //var(--secondary);
    --bg: transparent; //var(--primary);
    --padding-v: var(--padding-s);
    --padding-h: var(--padding-xs);
    --font-size: var(--font-size-base);
}

#Header {
    @include font-sans();
    @include z-index($z-index-header);

    position: fixed;
    width: var(--width);
    height: var(--height);
    right: 0;
    top: 0;
    padding: var(--padding-v) var(--padding-h);

    display: flex;
    justify-content: center;
    align-items: center;

    color: var(--color);
    background-color: var(--bg);

    // transform: translate3d(0, var(--y-header), 10px);

    text-transform: uppercase;
    font-size: var(--font-size-xsmall);

    p {
      text-align: center;
    }
    
    span {
        @include font-sans-secondary();
      
        color: currentColor;
        font-size: var(--font-size-small);
        margin: 0 rem(3);
    }
}
