:root {
    --font-sans: 'HelveticaNeue-Roman', sans-serif;
    --font-sans-secondary: 'BebasNeue', sans-serif;

    --font-size-xxlarge: #{(360px / 16px) * 1rem};
    --font-size-large: #{(80px / 16px) * 1rem};
    --font-size-xlarge: #{(130px / 16px) * 1rem};
    --font-size-mid: #{(45px / 16px) * 1rem};
    --font-size-base: #{(18px / 16px) * 1rem};
    --font-size-h2: #{(80px / 16px) * 1rem};
    --font-size-h3: #{(40px / 16px) * 1rem};
    --font-size-small: #{(13px / 16px) * 1rem};
    --font-size-xsmall: #{(12px / 16px) * 1rem};

    --line-height-sans: 1.2;
    --line-height-sans-secondary: 1.18;
    --letter-spacing: -0.06em;
    --letter-spacing-secondary: 0.05em;

    font-size: 1.1111vw;

    @media (max-width: $smartphone) {
        font-size: 16px;
    }
}

@mixin font-sans($line-height: 0) {
    font-family: var(--font-sans);
    font-weight: normal;
    letter-spacing: var(--letter-spacing);
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-sans-secondary($line-height: 0) {
    font-family: var(--font-sans-secondary);
    font-weight: normal;
    letter-spacing: var(--letter-spacing-secondary);
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans-secondary);  }
}
